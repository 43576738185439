import React, { useEffect, useState } from "react";
import { Link, graphql } from "gatsby";

import Bio from "../components/bio";
import Layout from "../components/layout-main";
import SEO from "../components/seo";
import ArticleDisplay from "../components/ArticleDisplay";
import { getArticle, getArticleBySlug } from "../articleResource";

const BlogPost = ({ pageContext: { data } }) => {
  const [article, setArticle] = useState(data);

  useEffect(() => {
    (async () => {
      if (data) {
        setArticle(await getArticle(data.id));
      } else if (typeof window !== "undefined" && window) {
        const pathtokens = window.location.pathname.split("/");
        const lastToken = pathtokens[pathtokens.length - 1];
        if (lastToken !== "blog" && pathtokens.length > 1) {
          setArticle(await getArticleBySlug(lastToken));
        }
      }
    })();
  }, [data]);

  const extraMeta = [];

  if (data && data.image) {
    extraMeta.push({
      property: `og:image`,
      content: data.image,
    },
    {
      property: `og:url`,
      content: `https://chrisandquynh.com/blog/${data.slug}/`
    });
  }
  return (
    <Layout>
      {data && (
        <SEO
          title={data.title}
          description={data.description || data.excerpt}
          meta={extraMeta}
          type={'article'}
        />
      )}
      <ArticleDisplay article={article || data}></ArticleDisplay>
    </Layout>
  );
};

export default BlogPost;
